import { useEffect, useState, VFC } from "react";
import InputField from "../common/InputField";

type inputType = "text" | "password";

interface Props {
  displayValue: string;
  inEdit: boolean;
  onChange: (newVal: string) => void;
  type?: inputType;
  onPassChanged?: (newPass: string) => void;
}

const AccountSettingEdit: VFC<Props> = (props) => {
  const [localInput, setLocalInput] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");

  // Clean up on edit mode changed.
  useEffect(() => {
    setLocalInput("");
    setOldPassword("");
  }, [props.inEdit]);

  const onInputChange = (newVal: string) => {
    props.onChange(newVal);
    setLocalInput(newVal);
  };

  const onPasswordChanged = (newPass: string) => {
    if (props.onPassChanged) {
      props.onPassChanged(newPass);
      setOldPassword(newPass);
    }
  };

  const genEditComponent = () => {
    switch (props.type) {
      case "password":
        return (
          <div className="flex flex-row">
            <InputField
              type="password"
              placeholder="Old Password"
              className="mr-4"
              value={oldPassword}
              onChange={onPasswordChanged}
            />

            <InputField
              type="password"
              placeholder="New Password"
              value={localInput}
              onChange={onInputChange}
            />
          </div>
        );
      default:
        return (
          <InputField type="text" value={localInput} onChange={onInputChange} />
        );
    }
  };

  return props.inEdit ? (
    genEditComponent()
  ) : (
    <span className="w-1/2">{props.displayValue}</span>
  );
};

export default AccountSettingEdit;
