import { useSnackbar } from "notistack";
import { useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineLogout } from "react-icons/md";
import {
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NotificationIcon from "../components/icons/Notification";
import SettingIcon from "../components/icons/Settings";
import SidebarMenu from "../components/sidebar/SidebarMenu";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: React.ReactNode;
}

const MainLayout: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  return (
    <div className="bg-background flex flex-row">
      <div className="bg-background w-80" style={{ height: "80hv" }}>
        <div className="flex justify-center items-center h-32">
          <a
            href="https://www.vritimes.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="h-48" src="/VRITTA_logo.png" alt="vritimes_logo" />
          </a>
        </div>
        {/* Reminder: change calculated height when logo height changed. */}
        <div className="flex flex-col justify-between h-[calc(100%_-_8rem)]">
          <div className="flex-col p-8 grid">
            <div className="flex flex-col space-between">
              <SidebarMenu
                active={location.pathname === "/setting/reception"}
                text={t("sidemenu.reception_setting")}
                onClick={() => {
                  navigate("/setting/reception");
                }}
                icon={<NotificationIcon />}
              />
              <SidebarMenu
                active={location.pathname === "/setting/account"}
                text={t("sidemenu.account_setting")}
                onClick={() => {
                  navigate("/setting/account");
                }}
                icon={<SettingIcon />}
              />
            </div>
          </div>
          <div className="flex-col p-8 grid mt-64">
            <button
              onClick={() => {
                props.signout(navigate);
                enqueueSnackbar("Sign out succeeded.", { variant: "success" });
              }}
              className="flex p-4 font-bold hover:bg-zinc-300 text-left"
              type="button"
            >
              <MdOutlineLogout size="24px" className="mr-2" />
              {t("sidemenu.sign_out")}
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white w-full shadow-xl">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
