export const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    endpoint: process.env.REACT_APP_AWS_COGNITO_ENDPOINT,
    // TODO: check to see if SRP works locally.
    // authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  
};
