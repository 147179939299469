import { useSnackbar } from "notistack";
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import CognitoAdapter from "src/adapter/CognitoAdapter";
import Button from "../components/common/Button";
import InputField from "../components/common/InputField";
import PasswordResetModal from "../components/modal/passwordReset/PasswordResetModal";

interface Props {
  singIn?: (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: () => void
  ) => Promise<boolean>;
  lang: string;
}

/**
 * Login page component.
 */
const SignInPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetModalVis, setResetModalVis] = useState<boolean>(false);

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  const onChangeEmail = (value: string) => {
    setEmail(value);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  const onFailedSignin = () => {
    enqueueSnackbar("Sign in failed.", { variant: "error" });
  };

  const onSucceededSignin = () => {
    enqueueSnackbar("Sign in succeeded.", { variant: "success" });
    navigate("dashboard");
  };

  const onClick = () => {
    if (props.singIn) {
      props.singIn(email, password, onSucceededSignin, onFailedSignin);
    }
  };

  const requestPasswordReset = async (
    username: string,
    onCompleted: () => void
  ) => {
    const result = await CognitoAdapter.requestPasswordReset(username);
    if (result === "SUCCESS") {
      enqueueSnackbar("Password Reset requested.", {
        variant: "success",
      });
      onCompleted();
    } else {
      enqueueSnackbar("Password Reset failed. Please check your inputs.", {
        variant: "error",
      });
    }
  };

  const resetPassword = async (
    username: string,
    authCode: string,
    newPassword: string,
    onCompleted: () => void
  ) => {
    const result = await CognitoAdapter.resetPassword(
      username,
      authCode,
      newPassword
    );
    if (result === "SUCCESS") {
      enqueueSnackbar(
        "Password Reset completed. Please login using your username",
        {
          variant: "success",
        }
      );
      setResetModalVis(false);
      onCompleted();
    } else if (result === "NotAuthorizedException") {
      enqueueSnackbar("Current Password is incorrect", { variant: "error" });
    } else {
      enqueueSnackbar("Password Reset failed. Please check your inputs.", {
        variant: "error",
      });
    }
  };

  return (
    <main className="flex-grow flex justify-center items-center">
      <div className="shadow-md max-w-sp w-96 p-8 bg-white rounded-2xl">
        <div className="flex justify-center w-full text-2xl font-bold">
          {t("sign_in.title")}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <InputField
              label={t("sign_in.email.label")}
              type="text"
              value={email}
              onChange={onChangeEmail}
              placeholder={t("sign_in.email.placeholder")}
            />
          </div>
          <div>
            <InputField
              label={t("sign_in.password.label")}
              type="password"
              value={password}
              onChange={onChangePassword}
              placeholder={t("sign_in.password.placeholder")}
            />
          </div>
          {/* TODO: Add the features to save information and to reveal password. */}
          <div className="flex flex-row-reverse justify-between">
            <button
              className="text-sm text-primary hover:text-blue-900"
              type="button"
              onClick={() => setResetModalVis(true)}
            >
              {t("sign_in.forget.btn")}
            </button>
          </div>
          <div>
            <Button
              onClick={onClick}
              text={t("sign_in.button")}
              size="large"
              isFullWidth
            />
          </div>
        </div>
      </div>
      <PasswordResetModal
        open={resetModalVis}
        handleClose={() => setResetModalVis(false)}
        requestPasswordReset={requestPasswordReset}
        resetPassword={resetPassword}
      />
    </main>
  );
};

export default SignInPage;
