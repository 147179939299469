import { VFC } from "react";

interface Props {
  title: string;
}

const PageHeader: VFC<Props> = (props: Props) => {
  return (
    <div>
      <div className="font-bold text-2xl flex py-8">{props.title}</div>
    </div>
  );
};

export default PageHeader;
