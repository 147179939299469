import { BusinessCategory } from "./BusinessCategory";
import { FavoriteCountry } from "./FavoriteCountry";

export interface UserInfoResponse {
  id: string;
  email: string;
  password: string;
  publisher_id: string;
  categories: BusinessCategory[];
  countries: FavoriteCountry[];
  pic_name: string;
}

export interface UpdateUserCategoryReq {
  media_user_id: string;
  favorite_categories: BusinessCategory[];
}

export const genUpdateUserCategoryRequest = (
  id: string,
  catetories: BusinessCategory[]
): UpdateUserCategoryReq => {
  return {
    media_user_id: id,
    favorite_categories: catetories,
  };
};
