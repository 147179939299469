interface Props {
  filled?: boolean;
}

const NotificationIcon = (props: Props): JSX.Element => {
  const { filled, ...others } = props;
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M12.3748 23.368C11.2259 23.368 10.2945 22.4367 10.2945 21.2878H14.455C14.455 22.4367 13.5236 23.368 12.3748 23.368ZM20.6958 20.2476H4.05371V18.1674L6.13397 17.1272V11.4065C6.13397 7.8056 7.612 5.47051 10.2945 4.83291V2.56543H14.455V4.83291C17.1375 5.46947 18.6155 7.80352 18.6155 11.4065V17.1272L20.6958 18.1674V20.2476ZM12.3748 6.46592C11.1054 6.38396 9.88133 6.95195 9.12434 7.9741C8.47691 8.99817 8.15925 10.1962 8.21423 11.4065V18.1674H16.5353V11.4065C16.5902 10.1962 16.2725 8.9982 15.6252 7.9741C14.8682 6.95195 13.6441 6.38396 12.3748 6.46592Z"
        fill={filled ? "#0045FF" : "#2D2D2D"}
      />
    </svg>
  );
};

export default NotificationIcon;
