interface Props {
  filled?: boolean;
}

const SettingIcon = (props: Props): JSX.Element => {
  const { filled, ...others } = props;
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M13.6954 24.9551C12.0323 24.9598 10.3902 24.5835 8.89502 23.8551C8.34782 23.5889 7.82354 23.278 7.32752 22.9256L7.17682 22.8156C5.81262 21.8086 4.69728 20.5025 3.91642 18.9975C3.10873 17.4397 2.68982 15.7097 2.69537 13.9551C2.69537 7.87994 7.62029 2.95508 13.6954 2.95508C19.7705 2.95508 24.6955 7.87994 24.6955 13.9551C24.7009 15.7089 24.2824 17.4381 23.4755 18.9953C22.6957 20.4994 21.5819 21.8051 20.2195 22.8123C19.7055 23.1885 19.1601 23.5197 18.5893 23.8023L18.5013 23.8463C17.0052 24.5785 15.3611 24.9579 13.6954 24.9551ZM13.6954 19.455C12.047 19.4518 10.5358 20.3725 9.78272 21.8388C12.2482 23.06 15.1426 23.06 17.6081 21.8388V21.8333C16.854 20.3686 15.3428 19.45 13.6954 19.455ZM13.6954 17.2551C16.0781 17.2582 18.2752 18.5422 19.4473 20.6167L19.4638 20.6024L19.4792 20.5892L19.4605 20.6057L19.4495 20.6145C22.2314 18.2111 23.2261 14.3316 21.9439 10.8862C20.6617 7.44074 17.3728 5.15542 13.6965 5.15542C10.0202 5.15542 6.73131 7.44074 5.44911 10.8862C4.1669 14.3316 5.16162 18.2111 7.94352 20.6145C9.1164 18.541 11.3132 17.258 13.6954 17.2551ZM13.6954 16.1551C11.2654 16.1551 9.29542 14.1851 9.29542 11.7551C9.29542 9.32502 11.2654 7.35507 13.6954 7.35507C16.1255 7.35507 18.0954 9.32502 18.0954 11.7551C18.0954 12.922 17.6318 14.0412 16.8067 14.8663C15.9815 15.6915 14.8624 16.1551 13.6954 16.1551ZM13.6954 9.55507C12.4804 9.55507 11.4954 10.54 11.4954 11.7551C11.4954 12.9701 12.4804 13.9551 13.6954 13.9551C14.9104 13.9551 15.8954 12.9701 15.8954 11.7551C15.8954 10.54 14.9104 9.55507 13.6954 9.55507Z"
        fill={filled ? "#0045FF" : "#2D2D2D"}
      />
    </svg>
  );
};

export default SettingIcon;
