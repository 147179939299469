import { VFC } from "react";

interface Props {
  title: string;
  children?: JSX.Element;
  actionArea?: React.ReactElement;
}

const AccountSettingWrapper: VFC<Props> = (props) => {
  return (
    <div className="flex flex-row sm:w-full lg:w-5/4 xl:w-3/4">
      <div className="flex items-center h-16 basis-3/4 mr-2 border-b-2 ">
        <div className="flex items-center w-full">
          <span className="text-sm w-1/4">{props.title}</span>
          {props.children}
        </div>
      </div>
      {props.actionArea ? (
        <div className="flex flex-row items-center justify-around basis-1/4">
          {props.actionArea}
        </div>
      ) : null}
    </div>
  );
};

export default AccountSettingWrapper;
