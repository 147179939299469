/* eslint-disable @typescript-eslint/no-explicit-any */
import { CognitoUser } from "@aws-amplify/auth";

class LoginUserRepository {
  private static repository = new LoginUserRepository();

  public idToken = "";

  public static getInstance = () => LoginUserRepository.repository;

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    /** Singleton */
  }

  public login = (user: CognitoUser) => {
    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
    if (token) {
      this.idToken = token;
    }
  };

  /**
   * セッションを保持している場合の処理 idTokenを保持する
   */
  public addSession = (session: any) => {
    const token = session.getIdToken().getJwtToken();
    if (token) {
      this.idToken = token;
    }
  };

  /**
   * IDTokenを削除
   */
  public logout = () => {
    this.idToken = "";
  };
}

export default LoginUserRepository;
