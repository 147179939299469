/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/CloseOutlined";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { RequestPasswordResetPanel } from "./parts/RequestPasswordResetPanel";
import { ResetPasswordPanel } from "./parts/ResetPasswordPanel";

interface Props {
  resetPassword: (
    username: string,
    authCode: string,
    newPassword: string,
    onCompleted: () => void
  ) => Promise<void>;
  requestPasswordReset: (
    username: string,
    onCompleted: () => void
  ) => Promise<void>;
  open: boolean;
  handleClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: 600,
  },
  closeButton: {
    position: "absolute",
    right: 1,
    top: 1,
  },
}));

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

const PasswordResetModal: VFC<Props> = (props: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const [isRequested, setIsRequested] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface EmptyObject {}
  const handleChange = (_: ChangeEvent<EmptyObject>, newValue: number) => {
    setValue(newValue);
  };

  const onPasswordResetRequested = (onCompleted: () => void) => {
    if (username) {
      props.requestPasswordReset(username, () => {
        onCompleted();
        setIsRequested(true);
      });
    }
  };

  const onPasswordReset = (
    authCode: string,
    newPassword: string,
    onCompleted: () => void
  ) => {
    props.resetPassword(username, authCode, newPassword, () => {
      onCompleted();
      onModalClose();
    });
  };

  const onModalClose = () => {
    setIsRequested(false);
    setUsername("");
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <div className="flex flex-row-reverse">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onModalClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex justify-center">
          <div className={classes.container}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={t("sign_in.forget.tag.label")} />
            </Tabs>
            <Divider />
            <TabPanel value={value} index={0}>
              {!isRequested ? (
                <RequestPasswordResetPanel
                  userName={username}
                  setUserName={setUsername}
                  requestPasswordReset={onPasswordResetRequested}
                />
              ) : (
                <ResetPasswordPanel
                  resetPassword={onPasswordReset}
                  modalOpen={props.open}
                />
              )}
            </TabPanel>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordResetModal;
