import { ReactNode, useEffect, useState, VFC } from "react";
import {
  BusinessCategory,
  ParentCategory,
} from "src/entities/BusinessCategory";
import { FavoriteCountry } from "src/entities/FavoriteCountry";
import { BaseSetting } from "src/entities/base";
import SettingSectionWrapper from "./SettingSectionWrapper";
import SubCategorySelection from "./SubCategorySelection";

type CategoryType = "reception" | "country";

type IncomingParentType = ParentCategory[] | FavoriteCountry[];
interface Props {
  parentCategories: IncomingParentType;
  type: CategoryType;
  onUpdate: (categories: BusinessCategory[]) => Promise<boolean>;
  userPreference?: BaseSetting[];
}

const BusinessReceptionSetting: VFC<Props> = (props: Props) => {
  const [receptionOptions, setReceptionOptions] = useState<BaseSetting[]>([]);

  useEffect(() => {
    if (props.userPreference) {
      setReceptionOptions(props.userPreference);
    }
  }, [props.userPreference]);

  const onSettingChanged = (category: BusinessCategory, checked: boolean) => {
    const optionExsited = receptionOptions.some(
      (option) => option.id === category.id
    );
    let newReceptionOptions;
    if (checked && !optionExsited) {
      newReceptionOptions = [...receptionOptions, category];
    } else if (!checked) {
      newReceptionOptions = receptionOptions.filter(
        (option) => option.id !== category.id
      );
    }
    setReceptionOptions(newReceptionOptions);
    props.onUpdate(newReceptionOptions);
  };

  const genParentCategorySelection = (
    categories: ParentCategory[]
  ): ReactNode => {
    return categories?.map((cat) => (
      <SettingSectionWrapper key={cat.id} title={cat.name}>
        <SubCategorySelection
          type = "categories"
          categories={cat.categories}
          selectedValue={receptionOptions}
          onChange={onSettingChanged}
        />
      </SettingSectionWrapper>
    ));
  };

  const genCountryCategorySelections = (
    categories: FavoriteCountry[]
  ): ReactNode => {
    return (
      <SettingSectionWrapper key="reception-country" title="">
        <SubCategorySelection
          type = "countries"
          categories={categories}
          selectedValue={receptionOptions}
          onChange={onSettingChanged}
        />
      </SettingSectionWrapper>
    );
  };

  const genSettingSelections = (categories: IncomingParentType): ReactNode => {
    if (props.type === "reception") {
      return <>{genParentCategorySelection(categories as ParentCategory[])}</>;
    } else if (props.type === "country") {
      return (
        <>{genCountryCategorySelections(categories as FavoriteCountry[])}</>
      );
    } else {
      // TODO: think about error handling.
      return <div />;
    }
  };

  return <>{genSettingSelections(props.parentCategories)}</>;
};

export default BusinessReceptionSetting;
