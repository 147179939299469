export const ENV = {
  // Auth
  COGNITO_ENDPOINT: process.env.REACT_APP_AWS_COGNITO_ENDPOINT || "",
  COGNITO_REGION: process.env.REACT_APP_AWS_COGNITO_REGION || "",
  COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID || "",
  COGNITO_USER_CLIENT_ID:
    process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID || "",

  // MEDIA Service
  MEDIA_ENDPOINT: process.env.REACT_APP_MEDIA_ENDPOINT || "",
  MEDIA_APIKEY: process.env.REACT_APP_MEDIA_APIKEY || "",

  // USER Service
  USER_ENDPOINT: process.env.REACT_APP_USER_ENDPOINT || "",
  USER_APIKEY: process.env.REACT_APP_USER_APIKEY || "",
};
