import { VFC } from "react";
import ContentGroupWrapper from "../../content/ContentGroupWrapper";

interface Props {
  title: string;
  children?: JSX.Element | JSX.Element[];
}

const SettingSectionWrapper: VFC<Props> = (props) => {
  return (
    <div className="basis-1/2 pr-16">
      <ContentGroupWrapper title={props.title}>
        {props.children}
      </ContentGroupWrapper>
    </div>
  );
};

export default SettingSectionWrapper;
