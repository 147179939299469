/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import { useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router";
import CognitoAdapter from "src/adapter/CognitoAdapter";
import { SUCCESS } from "src/const/const";
import { ENV } from "src/const/env";
import { UserInfoResponse } from "src/entities/UserInfoResponse";
import { useFetch } from "src/hooks/useFetch";
import PageContentsWrapper from "../components/page/PageContentsWrapper";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";
import AccountSettingRow from "../components/setting/AccountSettingRow";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
}

const AccountSettingPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const dummyPassword = "●●●●●●";

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  const { data: userInfo, isLoading: isUsesrInfoLoading } =
    useFetch<UserInfoResponse>({
      url: `${ENV.USER_ENDPOINT}/media_user`,
      onError: () => {
        enqueueSnackbar(
          "An unexpected error has occurred. Please sign in again.",
          {
            variant: "error",
          }
        );
        props.signout(navigate);
      },
    });

  const updateItem = async (newValue: string): Promise<boolean> => {
    const result = new Promise<boolean>((resolve) => {
      if (newValue) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
    return result;
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<boolean> => {
    const result = await CognitoAdapter.changePassword(
      oldPassword,
      newPassword
    );
    if (result === SUCCESS) {
      enqueueSnackbar("Password Change succeeded.", { variant: "success" });
      return true;
    } else {
      enqueueSnackbar(
        `Password change is failed with Error Message: ${result}`,
        {
          variant: "error",
        }
      );
      return false;
    }
  };

  return (
    <PageWrapper>
      <PageHeader title={t("account.page_header")} />
      <PageContentsWrapper>
        {isUsesrInfoLoading ? (
          <div className="flex h-full w-full justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex flex-col py-4">
            <AccountSettingRow
              title={t("account.title.name")}
              value={userInfo && userInfo.pic_name}
              onSubmit={updateItem}
              readonly
            />
            <AccountSettingRow
              title={t("account.title.email")}
              value={userInfo && userInfo.email}
              onSubmit={updateItem}
              readonly
            />
            <AccountSettingRow
              title={t("account.title.password")}
              type="password"
              value={dummyPassword}
              onSubmit={changePassword}
            />
          </div>
        )}
      </PageContentsWrapper>
    </PageWrapper>
  );
};

export default AccountSettingPage;
