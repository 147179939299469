import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { VFC, ReactFragment, ReactNode } from "react";

interface Props {
  title: string | ReactFragment;
  children?: ReactNode;
}

const useStyles = makeStyles(() => ({
  textGrid: {
    display: "flex",
    alignItems: "center",
  },
  textFieldTitle: {
    color: "#5F6885",
    fontWeight: "bold",
  },
}));

export const InputWrapper: VFC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12}>
      <Grid className={classes.textGrid} item xs={5}>
        <Typography className={classes.textFieldTitle}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {props.children}
      </Grid>
    </Grid>
  );
};
