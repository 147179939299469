/** @jsxImportSource @emotion/react */
import { Switch } from "@mui/material";
import { ChangeEvent, VFC } from "react";

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value: boolean;
  label?: string;
}

const StyledSwitch: VFC<Props> = (props: Props) => {
  return (
    // TODO: required flag
    <div className="flex justify-between items-center">
      {props.label ? (
        <span className="text-text-main">{props.label}</span>
      ) : null}
      <Switch checked={props.value} onChange={props.onChange} />
    </div>
  );
};

export default StyledSwitch;
