/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router";
import { ENV } from "src/const/env";
import {
  BusinessCategory,
  ParentCategoryResponse,
} from "src/entities/BusinessCategory";
import {
  FavoriteCountry,
  FavoriteCountryResponse,
  genUpdateFavoriteCountryRequest,
} from "src/entities/FavoriteCountry";
import {
  genUpdateUserCategoryRequest,
  UserInfoResponse,
} from "src/entities/UserInfoResponse";
import { useAuth } from "src/hooks/useAuth";
import { useFetch } from "src/hooks/useFetch";
import ContentHeader from "../components/content/ContentHeader";
import PageContentsWrapper from "../components/page/PageContentsWrapper";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";
import BusinessReceptionSetting from "../components/setting/reception/BusinessReceptionSetting";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
}

const DashboardPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { authInfo } = useAuth();

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  const { data: parentCategories, isLoading: isCategoryLoading } =
    useFetch<ParentCategoryResponse>({
      url: `${ENV.MEDIA_ENDPOINT}/v1/parent_categories`,
      onError: () => {
        enqueueSnackbar(
          "An unexpected error has occurred. Please sign in again.",
          {
            variant: "error",
          }
        );
        props.signout(navigate);
      },
    });

  const { data: favoriteCountries, isLoading: isCountryLoading } =
    useFetch<FavoriteCountryResponse>({
      url: `${ENV.MEDIA_ENDPOINT}/v1/countries`,
      onError: () => {
        enqueueSnackbar(
          "An unexpected error has occurred. Please sign in again.",
          {
            variant: "error",
          }
        );
        // props.signout(navigate);
      },
    });

  const { data: userInfo, isLoading: isUsesrInfoLoading } =
    useFetch<UserInfoResponse>({
      url: `${ENV.USER_ENDPOINT}/media_user`,
      onError: () => {
        enqueueSnackbar(
          "An unexpected error has occurred. Please sign in again.",
          {
            variant: "error",
          }
        );
        props.signout(navigate);
      },
    });

  const postChanges = async (
    url: string,
    changes: unknown
  ): Promise<boolean> => {
    return axios
      .request({
        method: "PATCH",
        url,
        data: changes,
        headers: {
          Authorization: `Bearer ${authInfo.accessToken}`,
        },
      })
      .then((res) => res.status <= 400)
      .catch(() => {
        enqueueSnackbar("Update settings failed. Please try again.", {
          variant: "error",
        });
        return false;
      });
  };

  const updateReceptionSetting = (categories: BusinessCategory[]) =>
    postChanges(
      `${ENV.MEDIA_ENDPOINT}/v1/media_users/favorite_categories`,
      genUpdateUserCategoryRequest(userInfo?.id, categories)
    );

  const updateFavoriteCountries = (countries: FavoriteCountry[]) =>
    postChanges(
      `${ENV.MEDIA_ENDPOINT}/v1/media_users/favorite_countries`,
      genUpdateFavoriteCountryRequest(userInfo?.id, countries)
    );

  return (
    <PageWrapper>
      <PageHeader title={t("reception.page_header")} />
      <PageContentsWrapper>
        {isCategoryLoading || isCountryLoading || isUsesrInfoLoading ? (
          <div className="flex h-full w-full justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <ContentHeader title={t("reception.country.header")} />
            <div className="flex flex-wrap pb-8">
              {favoriteCountries && (
                <BusinessReceptionSetting
                  parentCategories={favoriteCountries.countries}
                  type="country"
                  userPreference={userInfo.countries}
                  onUpdate={updateFavoriteCountries}
                />
              )}
            </div>
            <ContentHeader title={t("reception.business.header")} />
            <div className="flex flex-wrap pb-8">
              {parentCategories && (
                <BusinessReceptionSetting
                  parentCategories={parentCategories.parent_categories}
                  type="reception"
                  userPreference={userInfo.categories}
                  onUpdate={updateReceptionSetting}
                />
              )}
            </div>
          </>
        )}
      </PageContentsWrapper>
    </PageWrapper>
  );
};

export default DashboardPage;
