/* eslint-disable no-else-return */
import { Auth } from "aws-amplify";
import { createContext, useContext, useState } from "react";
import { NavigateFunction } from "react-router";
import CognitoAdapter from "src/adapter/CognitoAdapter";

export interface Auth {
  isAuth: boolean;
  organizationId: string;
  authInfo: LoginSuccessResponse;
  signin: (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: () => void
  ) => Promise<boolean>;
  signout: (navigate: NavigateFunction) => boolean;
  checkSession: () => Promise<boolean>;
}

interface LoginSuccessResponse {
  accessToken: string;
  refreshToken: string;
}

const AuthContext = createContext<Auth | null>(null);

type Props = {
  children: JSX.Element;
};

export function ProvideAuth({ children }: Props) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth(): Auth {
  const [isAuth, setIsAuth] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [authInfo, setAuthInfo] = useState<LoginSuccessResponse>({
    accessToken: "",
    refreshToken: "",
  });

  const signin = async (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: () => void
  ) => {
    try {
      return await CognitoAdapter.login(email, password).then((res) => {
        if (res.authResult) {
          const { accessToken, refreshToken } = res;
          setIsAuth(true);
          setAuthInfo({ accessToken, refreshToken });
          onSuccess();
          return true;
        } else {
          onError();
          return false;
        }
      });
    } catch (error) {
      console.error(error);
      onError();
      return false;
    }
  };

  const signout = (navigate: NavigateFunction) => {
    CognitoAdapter.logout();
    setAuthInfo({ accessToken: "", refreshToken: "" });
    setIsAuth(false);
    navigate("/signin");
    return true;
  };

  const checkSession = async () => {
    const res = await CognitoAdapter.getSession();
    if (res.authResult) {
      const { accessToken, refreshToken } = res;
      setIsAuth(true);
      setAuthInfo({ accessToken, refreshToken });
      return true;
    } else {
      setIsAuth(false);
      return false;
    }
  };

  // Return the user object and auth methods
  return {
    isAuth,
    organizationId,
    authInfo,
    signin,
    signout,
    checkSession,
  };
}
