import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { VFC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/view/components/common/Button";
import InputField from "../../../common/InputField";
import { InputWrapper } from "../../fragments/InputWrapper";

interface Props {
  resetPassword: (
    authCode: string,
    newPassword: string,
    onCompleted: () => void
  ) => void;
  modalOpen: boolean;
}

const useStyles = makeStyles(() => ({
  content: {
    padding: "12px 0px",
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px 0px",
  },
  textField: {
    width: "100%",
  },
}));

export const ResetPasswordPanel: VFC<Props> = (props: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [authCode, setAuthCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmationPassword, setComfirmationPassword] = useState<string>("");

  const resetTextfield = () => {
    setAuthCode("");
    setNewPassword("");
    setComfirmationPassword("");
  };

  useEffect(() => {
    if (!props.modalOpen) {
      resetTextfield();
    }
  }, [props.modalOpen]);

  /**
   * execute reset method
   */
  const handleClickResetPassword = () => {
    if (authCode && newPassword) {
      props.resetPassword(authCode, newPassword, resetTextfield);
    }
  };

  return (
    <>
      <div className={classes.content}>
        <Grid container spacing={3}>
          <InputWrapper title={t("sign_in.forget.authcode.label")}>
            <InputField
              className={classes.textField}
              value={authCode}
              onChange={setAuthCode}
              type="text"
            />
          </InputWrapper>
          <InputWrapper title={t("sign_in.forget.newPass.label")}>
            <InputField
              className={classes.textField}
              value={newPassword}
              onChange={setNewPassword}
              type="password"
              placeholder={t("sign_in.forget.newPass.placeholder")}
            />
          </InputWrapper>
          <InputWrapper title={t("sign_in.forget.confirmPass.label")}>
            <InputField
              className={classes.textField}
              value={confirmationPassword}
              onChange={setComfirmationPassword}
              type="password"
            />
          </InputWrapper>
        </Grid>
      </div>
      <div className={classes.modalActions}>
        <Button
          text={t("sign_in.forget.execBtn")}
          variant="primary"
          disabled={
            !authCode ||
            !newPassword ||
            !confirmationPassword ||
            newPassword !== confirmationPassword
          }
          onClick={handleClickResetPassword}
          size="large"
          style={{
            width: 120,
            marginLeft: 12,
          }}
          />
      </div>
    </>
  );
};

// const varidatePassword = (password: string): boolean => {
//   const regex =
//     /^((?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d))(?=.*?[!-/:-@[-`{-~])[!-~]{8,64}$/;
//   return regex.test(password);
// };
