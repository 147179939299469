/** @jsxImportSource @emotion/react */
import { CSSProperties, VFC } from "react";

type variant = "primary" | "outlined";
type size = "small" | "normal" | "large";

interface Props {
  text: string;
  variant?: variant;
  size?: size;
  disabled?: boolean;
  isFullWidth?: boolean;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
}

const btnCommonStyle =
  "flex justify-center items-center content-center px-4 py-2 rounded-md";
const btnFilledStyle =
  "bg-primary hover:bg-button-hover disabled:border-gray-800 disabled:bg-gray-500 disabled:hover:bg-opacity-50 text-white";

/**
 * Common button component
 *
 * @param text button text
 * @param variant kind of button
 * @param disabled disabled flag
 * @param isFullWidth if you need 100% width, use this flag
 * @param onClick on click function
 * @returns
 */
const Button: VFC<Props> = (props: Props) => {
  let className: string;
  let btnSize: string;

  if (props.variant === "primary") {
    className = `${btnCommonStyle} ${btnFilledStyle} ${props.className}`;
  } else if (props.variant === "outlined") {
    className = `${btnCommonStyle}  border-solid border-2 border-primary bg-white hover:border-button-hover hover:text-button-hover disabled:border-opacity-50 disabled:hover:border-primary disabled:text-primary disabled:hover:border-opacity-50 disabled:text-opacity-50 disabled:hover:text-opacity-50 text-primary ${props.className}`;
  } else {
    className = `${btnCommonStyle} ${btnFilledStyle} ${props.className}`;
  }

  if (props.size === "small") {
    btnSize = "h-8";
  } else if (props.size === "large") {
    btnSize = "h-12";
  } else {
    btnSize = "h-10";
  }

  return (
    <button
      className={`${className} ${btnSize}`}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
      style={{
        width: props.isFullWidth ? "100%" : undefined,
        border: `0.5px solid ${props.disabled ? "#2D2D2D" : "#0045FF"}`,
        fontSize: 16,
        ...props.style,
      }}
    >
      {props.text}
    </button>
  );
};

export default Button;
