import { BaseSetting } from "./base";

export interface FavoriteCountryResponse {
  countries: FavoriteCountry[];
}

export type FavoriteCountry = BaseSetting;

export interface UpdateFavoriteCountryReq {
  media_user_id: string;
  favorite_countries: FavoriteCountry[];
}

export const genUpdateFavoriteCountryRequest = (
  id: string,
  catetories: FavoriteCountry[]
): UpdateFavoriteCountryReq => {
  return {
    media_user_id: id,
    favorite_countries: catetories,
  };
};
