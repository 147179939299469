import { Divider } from "@mui/material";
import { VFC } from "react";

interface Props {
  title: string;
}

const PageHeader: VFC<Props> = (props: Props) => {
  return (
    <div>
      {/* TODO: add login user information */}
      {/* <div className="flex flex-row-reverse"> User 1</div> */}
      <div className="font-bold text-2xl flex py-8">{props.title}</div>
      <Divider />
    </div>
  );
};

export default PageHeader;
