import { useEffect, VFC } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import LoginLayout from "./layouts/LoginLayout";
import MainLayout from "./layouts/MainLayout";
import AccountSettingPage from "./pages/AccountSettingPage";
import DashboardPage from "./pages/ReceptionSettingPage";
import SignInPage from "./pages/SignInPage";

const Root: VFC = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.checkSession();
  }, []);

  return (
    <Router>
      <Routes>
        {auth?.isAuth ? (
          <Route
            path="/"
            element={<MainLayout signout={auth.signout} lang="id" />}
          >
            <Route path="/" element={<Navigate to="dashboard" />} />
            <Route
              path="dashboard"
              element={<Navigate to="/setting/reception" />}
            />
            <Route
              path="setting/reception"
              element={<DashboardPage signout={auth.signout} lang="id" />}
            />
            <Route
              path="setting/account"
              element={<AccountSettingPage signout={auth.signout} lang="id" />}
            />
            <Route path="*" element={<Navigate to="dashboard" />} />
          </Route>
        ) : (
          <Route path="/" element={<LoginLayout />}>
            <Route path="/" element={<Navigate to="signin" />} />
            <Route
              path="signin"
              element={<SignInPage singIn={auth?.signin} lang="id" />}
            />
            <Route path="*" element={<Navigate to="signin" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
};

export default Root;
