import React, { VFC } from "react";

interface Props {
  active: boolean;
  text: string;
  onClick?: () => void;
  icon?: React.ReactElement;
}

const SidebarMenu: VFC<Props> = (props: Props) => {
  return (
    <div className="flex items-center hover:bg-zinc-300">
      {props.icon
        ? React.cloneElement(props.icon, {
            className: `w-8 h-8 `,
            filled: props.active,
          })
        : undefined}
      <button
        onClick={props.onClick}
        type="button"
        className={`flex p-4 w-full font-bold text-left ${
          props.active && "text-primary"
        }`}
      >
        {props.text}
      </button>
    </div>
  );
};

export default SidebarMenu;
