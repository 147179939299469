import { useState, VFC } from "react";
import Button from "../common/Button";
import AccountSettingEdit from "./AccountSettingEdit";
import AccountSettingWrapper from "./AccountSettingWrapper";

type inputType = "text" | "password";

type OnSubmitTypes = (...args: string[]) => Promise<boolean>;

interface Props {
  title: string;
  value: string;
  onSubmit: OnSubmitTypes;
  readonly?: boolean;
  type?: inputType;
}

const AccountSettingRow: VFC<Props> = (props) => {
  const [inEdit, setInEdit] = useState<boolean>(false);
  const [localInputs, setLocalInputs] = useState<string>("");
  // only used in password mode.
  const [oldPassword, setOldPassword] = useState<string>("");

  const onOk = async () => {
    let result: boolean;
    if (props.type === "password") {
      result = await props.onSubmit(oldPassword, localInputs);
    } else {
      result = await props.onSubmit(localInputs);
    }
    if (result) {
      setInEdit(false);
    }
  };

  const onCancel = () => setInEdit(false);

  return (
    <AccountSettingWrapper
      title={props.title}
      actionArea={
        <AccountSettingAction inEdit={inEdit} onOk={onOk} onCancel={onCancel} />
      }
    >
      <>
        <AccountSettingEdit
          displayValue={props.value}
          type={props.type || "text"}
          inEdit={inEdit}
          onChange={setLocalInputs}
          onPassChanged={setOldPassword}
        />

        {inEdit || props.readonly ? undefined : (
          <div className="flex flex-row-reverse w-1/4">
            <button
              className="px-4 text-primary"
              type="button"
              onClick={() => setInEdit(true)}
            >
              Edit
            </button>
          </div>
        )}
      </>
    </AccountSettingWrapper>
  );
};

interface ActionProps {
  inEdit: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const AccountSettingAction: VFC<ActionProps> = (props) => {
  return props.inEdit ? (
    <>
      <Button
        className="px-8"
        text="Cancel"
        variant="outlined"
        onClick={props.onCancel}
        style={{ width: 120 }}
      />
      <Button
        className="px-8"
        text="Save"
        variant="primary"
        onClick={props.onOk}
        style={{ width: 120 }}
      />
    </>
  ) : null;
};

export default AccountSettingRow;
