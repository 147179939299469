import { VFC } from "react";

type InputType = "text" | "password" | "tel";

interface Props {
  value: string | number;
  onChange: (value: string) => void;
  type: InputType;
  multiline?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
}

/**
 * @param value a value of state
 * @param onChange a function of changing the value
 * @param type a type of input, "text", "password", "tel"
 * @param multiline multiline flag
 * @param label a label of input field
 * @param placeholder a text of inline input field
 */
const InputField: VFC<Props> = (props: Props) => {
  return (
    // TODO: required flag
    <div className="flex flex-col">
      {props.label ? (
        <span className="text-text-main">{props.label}</span>
      ) : null}
      {props.multiline ? (
        <textarea
          className={`file:border border-solid text-text-main border-borderGray rounded-md w-100% p-4 text-base resize-none ${props.className}`}
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          placeholder={props.placeholder}
          rows={3}
        />
      ) : (
        <input
          className={`border border-solid text-text-main border-borderGray rounded-md h-12 w-100% px-4 ${props.className}`}
          type={props.type}
          value={props.value}
          onChange={(e) => {
            if (props.type === "tel") {
              /* eslint no-unused-expressions: "off" */
              /^\d*$/g.test(e.target.value) && props.onChange(e.target.value);
            } else {
              props.onChange(e.target.value);
            }
          }}
          placeholder={props.placeholder}
        />
      )}
    </div>
  );
};

export default InputField;
