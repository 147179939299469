import { CognitoUser } from "@aws-amplify/auth";
import { Amplify, Auth } from "aws-amplify";
import { awsConfig } from "src/const/awsConfigs";
import LoginUserRepository from "../repository/LoginUserRepository";

Amplify.configure(awsConfig);

interface LoginResult {
  accessToken?: string;
  refreshToken?: string;
  authResult?: boolean;
  error?: CognitoError;
}

interface CognitoError {
  code?: string;
  message?: string;
  name?: string;
}

export default class CognitoAdapter {
  public static login = async (
    username: string,
    password: string
  ): Promise<LoginResult> => {
    return Auth.signIn(username, password)
      .then((res) => {
        const cogintoUser = res as CognitoUser;
        LoginUserRepository.getInstance().login(cogintoUser);
        const userSession = cogintoUser.getSignInUserSession();
        return {
          accessToken: userSession?.getAccessToken().getJwtToken(),
          refreshToken: userSession?.getRefreshToken().getToken(),
          authResult: true,
        };
      })
      .catch((e) => {
        console.error(e);
        return { authResult: false, error: e };
      });
  };

  public static logout = async (): Promise<void> => {
    try {
      await Auth.signOut();
    } catch (e) {
      console.error(e);
    }
  };

  public static getSession = async (): Promise<LoginResult> => {
    return Auth.currentSession()
      .then((session) => ({
        authResult: true,
        accessToken: session.getAccessToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
      }))
      .catch((e) => {
        console.log(e);
        return { authResult: false, error: e };
      });
  };

  /**
   * Method to change password
   * @param oldPassword old password
   * @param newPassword new password
   * @returns SUCCESS or error message
   */
  public static changePassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<string> => {
    const result = Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        return data;
      })
      .catch((error: CognitoError) => {
        return error.code ? error.code : "UNDEFINED_ERROR";
      });

    return result;
  };

  /**
   * Method to start a Password reset request
   * @param username email for the reseting user.
   * @returns SUCCESS or error message
   */
  public static requestPasswordReset = async (
    username: string
  ): Promise<string> => {
    return Auth.forgotPassword(username)
      .then(() => {
        return "SUCCESS";
      })
      .catch((error: CognitoError) => {
        return error.code ? error.code : "UNDEFINED_ERROR";
      });
  };

  /**
   * Method to finish a Password reset
   * @param username email for the reseting user.
   * @param code password reset code from email
   * @param password new password
   * @returns 
   */
  public static resetPassword = async (
    username: string,
    code: string,
    password: string
  ): Promise<string> => {
    return Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        return "SUCCESS";
      })
      .catch((error: CognitoError) => {
        return error.code ? error.code : "UNDEFINED_ERROR";
      });
  };
}
