import { VFC } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
  title: string;
}

const ContentGroupWrapper: VFC<Props> = (props: Props) => {
  return (
    <div className="mb-8">
      <div className="font-medium text-xl text-primary flex pb-8">{props.title}</div>
      {props.children}
    </div>
  );
};

export default ContentGroupWrapper;
