import { VFC } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const PageWrapper: VFC<Props> = (props: Props) => {
  return (
    <main className="flex-grow py-8 px-12 h-screen overflow-auto">
      {props.children}
    </main>
  );
};

export default PageWrapper;
