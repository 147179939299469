import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/view/components/common/Button";
import InputField from "src/view/components/common/InputField";
import { InputWrapper } from "../../fragments/InputWrapper";

interface Props {
  userName: string;
  setUserName: (val: string) => void;
  requestPasswordReset: (onCompleted: () => void) => void;
}

const useStyles = makeStyles(() => ({
  content: {
    padding: "12px 0px",
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px 0px",
  },
  textField: {
    width: "100%",
  },
}));

export const RequestPasswordResetPanel: VFC<Props> = (props: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  /**
   * リセットボタン押下
   */
  const handleClickResetPassword = () => {
    props.requestPasswordReset(() => {
      return true;
    });
  };

  return (
    <>
      <div className={classes.content}>
        <Grid container spacing={3}>
          <InputWrapper title={t("sign_in.forget.username.label")}>
            <InputField
              className={classes.textField}
              value={props.userName}
              onChange={props.setUserName}
              type="text"
            />
          </InputWrapper>
        </Grid>
      </div>
      <div className={classes.modalActions}>
        <Button
          text={t("sign_in.forget.execBtn")}
          variant="primary"
          disabled={!props.userName}
          onClick={handleClickResetPassword}
          size="large"
          style={{
            width: 120,
            marginLeft: 12,
          }}
        />
      </div>
    </>
  );
};
